<template>
  <div>
    <div class="my-8 drivers">
    <h2 class="main-color">{{ $t("Login As Teacher") }}</h2>
    <v-data-table
      :options.sync="options"
      :loading="loading"
      loading-text="Loading... Please wait"
      :headers="headers"
      :items="items"
      item-key="id"
      :show-select="false"
      class="elevation-1 level1 templates text-center"
      text="left"
      hide-default-footer
    >
      <template v-slot:item.actions="{ item }">
        <v-btn @click="loginAsUser(item)" icon>
          <v-icon :title="$t('Login As This Teacher')">mdi-login</v-icon></v-btn
        >
      </template>
    </v-data-table>
    <div class="text-center my-2">
      <v-pagination
        v-if="length > 0"
        v-model="page"
        :length="length"
        :total-visible="totalVisible"
        circle
      ></v-pagination>
    </div>

  
    
  </div>

  
  </div>
</template>
<script>

import axios from "axios";

export default {
  name: "login-as-teacher",
  data() {
    return {
      options: {
        itemsPerPage: 15,
      },
    //   snack: "",
    //   types: [],
    //   dialog: false,
    //   snackTime: 3000,
    //   snackColor: "",
    //   snackText: "",
    //   filterMenu: false,
      loading: true,
    //   formTitle: "",
      selected: [],
      headers: [
        {
          text: this.$i18n.t("Name"),
          value: "name",
          sortable: false,
        },
        
        

        { text: this.$i18n.t("Actions"), value: "actions", sortable: false },
      ],
      title: this.$i18n.t("Login As Teacher"),
    
    
      items: [],
    
      circle: true,
      page: 1,
      length: "",
      totalVisible: "",
      totalItems: "",
      editedIndex: -1,
    };
  },
  watch: {
    "$route.query": {
      handler() {
        if (this.$route.query.page !== undefined) {
          this.page = Number(this.$route.query.page);
          this.getAllData(this.$route.query.page);
        } else {
          this.getAllData(1);
        }
      },
      deep: true,
    },
    page: function() {
      this.$router.push(
        {
          path: "/login-as-teacher?page=" + this.page,
        },
        () => {}
      );
    },

  },
  methods: {
   loginAsUser(user) {
      this.loading = true;
      axios
        .get(this.getApiUrl + "/impersonate/" + user.user_id, {
          headers: {
            Authorization: "Bearer " + localStorage.token,
            //the token is a variable which holds the token
          },
        })
        .then((response) => {
          this.loading = false;
          if (response.data.status.error == false) {
            let id = response.data.data.user.id;
            let token = response.data.data.access_token;
            let school = response.data.data.school;
            let logo = response.data.data.logo;
            let school_name = response.data.data.school_name;
            let gradeClass = response.data.data.gradeClass;
            localStorage.user_name = response.data.data.user.name;
            // localStorage to store user cradentials
            localStorage.id = id;
            localStorage.email = response.data.data.user.email;
            localStorage.lastLogin = response.data.data.lastLogin;
            localStorage.login = true;
            localStorage.token = token;
            localStorage.school = school;
            localStorage.logo = logo;
            localStorage.school_name = school_name;
            localStorage.gradeClass = gradeClass;
            localStorage.expiry = new Date().getTime() + 86400000; // 24 hours
            // localStorage.expiry = new Date().getTime() + 60000; // 1 minute
            localStorage.permissionsObject = JSON.stringify(
              response.data.data.permissions
            );
            localStorage.menus = JSON.stringify(response.data.data.menus);
            localStorage.roles = JSON.stringify(response.data.data.roles);
            localStorage.is_employee = response.data.data.is_employee;

            if (
              response.data.data.roles &&
              response.data.data.roles.length == 1
            ) {
              localStorage.selectedRole = response.data.data.roles[0];
            } else {
              localStorage.selectedRole = "";
            }
            localStorage.selectedSomeRole = false;
            localStorage.loginAs = response.data.data.login_as;
            if (localStorage.loginAs) {
              localStorage.old_user = response.data.data.old_user;
              localStorage.old_user_name = response.data.data.old_user_name;
            }
            localStorage.updateData = response.data.data.updateData;
            this.$router.push({
              path: `/`,
            });
            window.location = "/";
            // location.reload();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  
    getAllData(page = null) {
      if (page == null) {
        page = this.page;
      }
      axios
        .get(
          this.getApiUrl + "/users/getCoordinatorTeachers?page=" + page,
          {
            params: {
              filter: this.filter,
            },
            headers: {
              Authorization: "Bearer " + localStorage.token,
            },
          }
        )
        .then((response) => {
          this.setPaginationParameters(response);
          this.totalItems = response.data.data.total;
          this.items = [];
          this.items = response.data.data.data;
          this.loading = false;
        });
    },
    setPaginationParameters(response) {
      this.length = response.data.data.last_page;
      if (this.length > 5) this.totalVisible = 5;
    },
  },
  mounted() {
    // this.getTypes();
    if (this.$route.query.page !== undefined) {
      this.page = Number(this.$route.query.page);
      this.getAllData(this.page);
    } else {
      this.page = 1;
      this.getAllData(this.page);
    }
    
  },
};
</script>